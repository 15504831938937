import {modulesRoutes} from "../../modules";


function useRouteHook(context) {

    const route = (path, params) => {
        if(path === "index")    {
            return ["/", "/home"][context.lang];
        }
        if(!modulesRoutes[path])       {
            console.log("MISSING: ", `Ne postoji route: ${path}`);
            return "";
        }
        const compile = modulesRoutes[path].compile;
        if(compile)  {
            return compile(params, context.lang);
        }
        const r = modulesRoutes[path].fullpath;
        if (typeof r === 'string' || r instanceof String)   {
            return r;
        }
        if(!r[context.lang])   {
            console.log("MISSING: ", `Ne postoji route lang: ${path} lang: ${context.lang}`)
            return r[0];
        }
        return r[context.lang];
    }

    return {route}
}
export default useRouteHook;