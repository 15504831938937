import {lazy} from "react";
const Wall = lazy(() => import("./widgets/Wall"));


const exports = {
    routes: [

        ],
    widgets: {
        Wall: Wall,

    },
}
export default exports;
