import React from "react";

function Loader({graph, fluid, opacity}) {

    if(!graph) return null;

    const renderCol = (col, index) => {
        const colRows = col.split("+");

        let children = [];
        if(colRows.length > 1)  {
            children = colRows[1].split("@");
        }

        const params = colRows[0].split(",");
        let gotBasis = false;
        for(let i=0; i<params.length; i++)  {
            if(params[i].startsWith("basis"))   {
                gotBasis = true;
                break;
            }
        }
        const full = gotBasis ? "" : "flex-grow";
        const _ = <div className="hidden bg-gray-300 dark:bg-dark-1" />;
        const gray = children.length > 0 ? "" : "bg-gray-300 dark:bg-dark-1";
        return <div key={index} className={`${gray} animate-pulse rounded-md ${full} ${params.join(" ")}`}>
            {children.length > 0 &&
            <div>
                {children.map((child, childindex) => {
                    return <div key={childindex} className="flex">
                        {renderCol(child, childindex)}
                    </div>
                })}
            </div>}
        </div>
    }

    const container = fluid ? "" : "container mx-auto";
    const rows = graph.split(" ");
    return (
        <div className={container} style={{opacity: opacity || 1}}>
            {rows.map((row, rindex) => {
                const cols = row.split("|");
                return <div key={rindex} className="mb-3">
                    <div className={`md:flex flex-row gap-3`}>
                        {cols.map((col, cindex) => {
                            return renderCol(col, cindex);
                        })}
                    </div>
                </div>
            })}
        </div>
    )
}

export default Loader;
