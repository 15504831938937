import slugify from "slugify";
import {lazy} from "react";

//const BlogCarousel = lazy(() => import("./widgets/BlogCarousel")); TODO preload
import BlogCarousel from "./widgets/BlogCarousel";

const CmsBlogs = lazy(() => import("./views/cmsBlogs/CmsBlogs"));
const CmsBlog = lazy(() => import("./views/cmsBlog/cmsBlog"));
const Blogs = lazy(() => import("./views/blogs/Blogs"));
const Blog = lazy(() => import("./views/blog/Blog"));


const exports = {
    routes: [
        {
            name: "cmsBlogs",
            trans: ["Blog", "Blog"],
            path: ["vesti", "blogs"],
            element: <CmsBlogs />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 30}},
        },
        {
            name: "cmsBlog",
            path: ["vest/:id", "blog/:id"],
            element: <CmsBlog />,
            compile: (item, lang) => {
                return `/cms/${["vest", "blog"][lang]}/${item.id}`;
            },
            layouts: ["cms.CmsLayout"],
        },
        {
            name: "blogs",
            path: ["vesti", "blogs"],
            element: <Blogs />,
            layouts: ["public.PublicLayout"],
        },
        {
            name: "blog",
            path: ["vest/:id/:slug", "blog/:id/:slug"],
            element: <Blog />,
            compile: (item, lang) => {
                return `/${["vest", "blog"][lang]}/${item.id}/${slugify(item.title)}`;
            },
            layouts: ["public.PublicLayout"],
        },
    ],
    widgets: {
        BlogCarousel: BlogCarousel
    }
};
export default exports;