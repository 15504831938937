import {gql} from "@apollo/client";


export const PRODUCT_SPECIFICATION_FRAGMENT = gql`
    fragment ProductSpecificationFragment on ProductSpecification {
        id
        position
        name
        names
        value
        values
    }
`;

export const UPDATE_PRODUCT_SPECIFICATION = gql`
    ${PRODUCT_SPECIFICATION_FRAGMENT}
    mutation ($id: ID!, $name: String, $value: String)  {
        updateProductSpecification(id: $id, name: $name, value: $value) {
            ...ProductSpecificationFragment
        }
    }
`
