import {lazy} from "react";
import {providerCheckUser} from "./userProvider";
const AuthLayout = lazy(() => import("./views/layout/AuthLayout"));
const UserLayout = lazy(() => import("./views/layout/UserLayout"));
const LoginLink = lazy(() => import("./widgets/LoginLink"));
const Login = lazy(() => import("./views/login/Login"));
const Register = lazy(() => import("./views/register/Register"));
const Dashboard = lazy(() => import("./views/dashboard/Dashboard"));
const Email = lazy(() => import("./views/email/Email"));
const Reset = lazy(() => import("./views/reset/Reset"));
const Guard = lazy(() => import("./widgets/Guard"));
const CmsUsers = lazy(() => import("./views/cmsUsers/CmsUsers"));
//const LoginDrawer = lazy(() => import("./widgets/LoginDrawer"));


const exports = {
    routes: [
        {name: "AuthLayout", path: ["nalog", "account"], element: <AuthLayout />},
        {name: "UserLayout", path: ["korisnik", "user"], element: <UserLayout />},
        {name: "login", path: ["", "login"], element: <Login />, layouts: ["public.PublicLayout", "users.AuthLayout"]},
        {name: "register", path: ["registrujte-se", "register"], element: <Register />, layouts: ["public.PublicLayout", "users.AuthLayout"]},
        {name: "email", path: ["lozinka", "password"], element: <Email />, layouts: ["public.PublicLayout", "users.AuthLayout"]},
        {name: "reset", path: ["resetuj/:token", "reset/:token"], element: <Reset />, layouts: ["public.PublicLayout", "users.AuthLayout"]},
        {
            name: "dashboard",
            path: ["", "dashboard"],
            trans: ["Dashboard", "Dashboard"],
            element: <Dashboard />,
            layouts: ["public.PublicLayout", "users.UserLayout"]
        },
        {
            name: "cmsUsers",
            trans: ["Korisnici", "Users"],
            path: ["korisnici", "users"],
            element: <CmsUsers />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 36}},
        },
    ],
    widgets: {
        LoginLink: LoginLink,
        //LoginDrawer: LoginDrawer,
        Guard: Guard,
        providerCheckUser: providerCheckUser
    }

}
export default exports;