import {modulesRoutes} from "../../modules";
import {matchRoutes} from "react-router-dom";
import {from, useApolloClient} from "@apollo/client";
import {authLink, uploadLink} from "../../_app/apolloClient";


function useLangMatch(context) {
    const client = useApolloClient();
    const routes = Object.values(modulesRoutes);
    const holder = [];
    for(let i=0; i<routes.length; i++)    {
        for(let j=0; j<routes[i].fullpath.length; j++)    {
            holder.push({path: routes[i].fullpath[j], lang: j});
        }
    }
    const test = matchRoutes(holder, window.location.pathname);
    let lang = 0;
    if(test && test.length > 0) {
        lang = test[0]?.route?.lang || 0;
        context.lang = lang;
        if(lang !== 0)  {                      // Apdate apollo client with lang
            client.setLink(from([authLink(lang), uploadLink()]));
        }
    }
    return {lang}
}
export default useLangMatch;