import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

/*
    SCROLL PAGE TO TOP AFTER PAGE CHANGE
 */
function ScrollToTop() {
    const location = useLocation();
    const [prev, setPrev] = useState("");

    useEffect(() => {
        if(location.pathname !== prev)  {
            window.scrollTo(0, 0);
        }
        setPrev(location.pathname);
    }, [location]);
    return null;
}

export default ScrollToTop;
