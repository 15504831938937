import {server} from "../../_app/config";

function storageFile(file) {
    if(file && file.name.endsWith(".pdf")) {
        return server("storage/000-pdf.png");
    }
    return server("storage/000-file.png");
}
function noImage() {
    return server("storage/000-no-image.png");
}
export function image(image, lg=false) {
    if (typeof image === "string") {
        const name = lg ? `lg-${image}` : image;
        return server(`storage/slike/${name}`);
    }
    if(!image)  {
        return noImage();
    }
    if(!image.width)    {
        return storageFile(image);
    }
    const name = lg ? `lg-${image.name}` : image.name;
    return server(`storage/slike/${image.folder}/${name}`);
}
export function link(image, lg=false) {
    if(!image.width)    {
        return server(`storage/fajlovi/${image.folder}/${image.name}`);
    }
    const name = lg ? `lg-${image.name}` : image.name;
    return server(`storage/slike/${image.folder}/${name}`);
}
