
export function randString(length= 6) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function setCookie(name, data, exdays=null) {
    var expires = '';
    if(exdays !== null)     {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        expires = "expires="+ d.toUTCString() + ";";
    }
    document.cookie = name + "=" + data + ";" + expires + "path=/";
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const formatPrice = (price) => {
    if(!price && price !== 0) return '';
    price = Number(parseFloat(price).toFixed(2));
    let strPrice = String(price);
    const exp = strPrice.split(".");
    strPrice = exp[0];
    if(price > 999)     {
        const pos = -3;
        strPrice = exp[0].slice(0, pos) + " " + exp[0].slice(pos);
    }
    if(price > 999999)     {
        const pos = -7;
        strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
    }
    if(exp[1])  {
        strPrice = strPrice + "." + exp[1];
    }
    return strPrice.replace(".", ",");
};
export function calculateDiscount(item) {
    if(!item.discount_price) return false;
    if(!item.price) return false;
    if(item.price === item.process_price) return false;
    return Math.round((item.price - item.discount_price)/item.price * 100);
}

export function youtubeLink(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
        ? "//www.youtube.com/embed/" + match[2]
        : null;
}
