
export function resetStructureData(name = "") {
    const structuredData = document.querySelector('script[type="application/ld+json"]');
    if(structuredData)      {
        structuredData.remove();
    }
}
export function seoAll(title, description, keywords, structData, image) {
    if(title) seoTitle(title);
    if(description) seoDescription(description);
    if(keywords) seoKeywords(keywords);
    if(structData) structuredData(structData);
    if(image)   ogImage(image);
}
export function seoTitle(title) {
    if(!title) return false;
    const tag = document.querySelector('title');
    if(!tag) {
        console.log("%cERROR MISSING: no head metatag TITLE", 'color: orange');
        return false;
    }
    tag.innerHTML = title;

    const ogTag = document.querySelector('meta[property="og:title"]');
    if(ogTag)   {
        ogTag.setAttribute("content", title);
    }
}
export function seoDescription(description) {
    if(!description) return false;
    const tag = document.querySelector('meta[name="description"]');
    if(!tag) {
        console.log("%cERROR MISSING: no head metatag DESCRIPTION", 'color: orange');
        return false;
    }
    tag.setAttribute("content", description);
}
export function seoKeywords(keywords) {
    if(!keywords) return false;
    const tag = document.querySelector('meta[name="keywords"]');
    if(!tag) {
        console.log("%cERROR MISSING: no head metatag KEYWORDS", 'color: orange');
        return false;
    }
    tag.setAttribute("content", keywords);
}
export function structuredData(data) {
    if(!data) return false;
    let el = document.querySelector('script[type="application/ld+json"]');
    if(!el)     {
        el = document.createElement("script");
        el.type = "application/ld+json";
        document.head.appendChild(el);
    }
    el.innerHTML = JSON.stringify(data);
}
export function ogUrl() {
    const ogTag = document.querySelector('meta[property="og:url"]');
    if(ogTag)   {
        ogTag.setAttribute("content", window.location.href);
    }
}
export function ogImage(data) {
    const ogTag = document.querySelector('meta[property="og:image"]');
    if(ogTag)   {
        if(!data)   {
            data = '/logo512.png';
        }
        ogTag.setAttribute("content", data);
    }
}