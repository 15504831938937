import {translations} from "../config";


function useTransHook(context) {

    const trans = (name) => {
        if(Array.isArray(name))  {
            if(!name[context.lang]) {
                return name[0];
            }
            return name[context.lang];
        }

        if(!translations[name]) {
            console.log("MISSING: ", `Ne postoji trans za: "${name}" lang: ${context.lang}`)
            return "*"+name;
        }
        if(!translations[name][context.lang]) {
            return name;
        }
        return translations[name][context.lang];
    }

    return {trans};
}
export default useTransHook;